import React, { useState,useEffect,useRef } from 'react';
import { Row, Col } from "react-bootstrap";
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import { AnimatePresence,color,motion } from 'framer-motion';
import Datex from '@drumtj/datex';
import Home from './Home'
import Flight from './Flight';
import Transport from './Transport';
import '../lib/bootstrap.min.css'
import './Main.css';
import Fnb from './Fnb';
import Clock from './ClockWidget';
import Helpdesk from './Helpdesk';
import Property from './Property';
import Welcome from './Welcome';
import License from './License';
import Setting from './Setting';

const Main = () => {
    var _valid = 100;
    const [hWindow, setHWindow] = useState(0);
    const [hHeader, setHHeader] = useState(110);
    const [hContent, setHContent] = useState(352);
    const [hFooter, setHFooter] = useState(80);
    const [isPage, setIsPage] = useState('welcome');
    const [clickCount,setClickCount] = useState(0)
    const [openModal,setOpenModal] = useState(false)
    const [weatherLoading, setWeatherLoading] = useState(false);
    const [weather,setWeather] = React.useState({
        lat: '-8.6610792',
        lon: '115.1872422',
        key:'0f1903d6af398d812da36080b1c80065',
        unit:'metric'
      })
    const ref = useRef(null);
    const worldClockRef = useRef()
    const [height,setHeight] = useState(0)
    const [width,setWidth] = useState(0)
    const getWeather = () =>{
        setWeatherLoading(true)
        const myHeaders = new Headers();
        const urlencoded = new URLSearchParams();
        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        
            fetch('https://api.openweathermap.org/data/2.5/weather?lat='+weather.lat+'&lon='+weather.lon+'&appid='+weather.key+'&units='+weather.unit, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    document.getElementById("weather_city").innerHTML = result.name+`<br />`+ new Datex(Date.now()).format("DDD, DD MMM YYYY")
                    document.getElementById("weather_time").innerHTML = new Datex(Date.now()).format("hh:mm")
                    document.getElementById("weather_icon").innerHTML = `
                    <img src='https://rodrigokamada.github.io/openweathermap/images/`+result.weather[0].icon + `_t@2x.png' height='80' alt='weather' />`
                    document.getElementById("weather_temp").innerHTML = parseInt(result.main.temp)+`<sup> o</sup>`
                    
                    setWeatherLoading(false)
                })
                .then((error)=>{
                    setWeatherLoading(false)
                })
        
    }
    const openPage = (page) =>{
        switch(isPage) {
            case 'home':
                if(isPage==='home' && page === 'home'){
                    setClickCount(clickCount+1)
                    if(clickCount>19){
                        setOpenModal(true)
                        /*var return_value=prompt("Password:");
                        if(return_value==="4554"){
                            page = 'setting'
                            setClickCount(0)
                        }*/
                    }
                }
              break;
            case 'worldclock':
                setClickCount(0)
                worldClockRef.current.exitPage()
              break;
            default:
                setClickCount(0)
          }
        setIsPage(page)
    }

    useEffect(() => {
        getWeather()
        setHeight(ref.current.clientHeight);
        setWidth(ref.current.clientWidth);
        setHWindow(ref.current.clientHeight);
        //document.getElementById('valid').innerHTML = 'test test'; //JSON.parse(window.ReactNativeWebView.injectedObjectJson());
        //setValid(document.getElementById('valid').innerHTML)
        const interval = setInterval(() => {
            //document.getElementById("header").style.height = hHeader + "px";
            //document.getElementById("content").style.height = ((ref.current.clientHeight)-(hHeader+hFooter))+ "px";
            //document.getElementById("footer").style.height = hFooter+ "px";
        }, 300000);

    }, []);


  return (
    <>
        <style>{`
            .tv{
                background:linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('https://vision.ebizpro.id/960x540/baleka/baleka-bg-tv.jpg') no-repeat;
                background-size: cover;
            }
            .header{
                height: `+hHeader+`px;
            }
            .content{
                height: `+hContent+`px;
            }
            .footer{
                height: `+hFooter+`px;
            }
            #modal-modal-description .btnHome{
                min-width:170px;
                margin: 0 5px 10px 5px
            }
        `}</style>
        <Mui.Modal
            open={openModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Mui.Box style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                background: '#ffffff',
                color: '#000000',
                border: '0px solid #000',
                boxShadow: 24,
                padding: '5px 15px',
            }}>
            <Mui.Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <button className="btnHome"  onClick={()=>{window.ReactNativeWebView.postMessage("openSetting")}}>
                        Setting
                    </button>
                    <button className="btnHome" onClick={()=>{window.ReactNativeWebView.postMessage("openConsole")}}>
                        Open Console
                    </button>
                    <button className="btnHome"  onClick={()=>{window.ReactNativeWebView.postMessage("openLauncher")}}>
                        Activate TV
                    </button>
                    <button className="btnHome"  onClick={()=>{setOpenModal(false); setClickCount(0)}}>
                        Close 
                    </button>
            </Mui.Typography>
            </Mui.Box>
        </Mui.Modal>
        <div className='tv' id='tv' ref={ref}>
            <div id='sn' style={{display:'none'}}></div>
            <Row className='header' id='header'>
                <Col md={7}>
                    <div className='header-left'>      
                        <div className="logo"><img src='bintangKutaHotel/images/logo.png' alt='logo' /></div>
                    </div>
                </Col>
                <Col md={5}>
                    <div className='header-right'>
                        <div className='weather' id='weather'>
                            <div id='weather_city'></div>
                            <div id='weather_time'></div>
                            <div id='weather_icon'></div>
                            <div id='weather_temp'></div>
                        </div>
                    </div>
                </Col>
            </Row>
            <div className='content'>
                {isPage === 'license' && (<License height={hContent} valid={_valid} goToHome={()=>{openPage('welcome')}} />)}
                {isPage === 'welcome' && (<Welcome height={hContent} goToHome={()=>{openPage('home')}} />)}
                {isPage === 'home' && (<Home height={hContent} propertyInfo={()=>{openPage('property')}} />)}
                {isPage === 'flight' && (<Flight height={hContent} />)}
                {isPage === 'transport' && (<Transport height={hContent} />)}
                {isPage === 'fnb' && (<Fnb height={hContent} />)}
                {isPage === 'helpdesk' && (<Helpdesk height={hContent} />)}
                {isPage === 'worldclock' && (<Clock ref={worldClockRef} height={hContent} page={isPage} />)}
                {isPage === 'property' && (<Property height={hContent} />)}
                {isPage === 'setting' && (<Setting height={hContent} />)}
            </div>
            <Row className='footer' id='footer'>
                <Col md={12}>
                    <button className='bmenu' tabIndex={1} 
                        onClick={()=>{ 
                            openPage('home')
                        }}
                    >
                        <img src='icons/home.png' alt='Property' height={27} />
                    </button>
                    <button className='bmenu' tabIndex={1} onClick={()=>{window.ReactNativeWebView.postMessage("openHomeTv")}}>
                        <img src='icons/tv.png' alt='Tv Channel' height={28} />
                    </button>
                    <button className='bmenu' tabIndex={2} onClick={()=>{window.ReactNativeWebView.postMessage("openYoutube")}}>
                        <img src='icons/youtube.png' alt='Youtube' height={24} />
                    </button>
                    <button className='bmenu' tabIndex={3} onClick={()=>{window.ReactNativeWebView.postMessage("openNetflix")}}>
                        <img src='icons/netflix.png' alt='Netflix' height={17} />
                    </button>
                    <button className='bmenu' tabIndex={4} onClick={()=>{ 
                        openPage('home')
                    }}>
                        <img src='icons/prime-video.png' alt='Prime Video' height={32} />
                    </button>
                    <button className='bmenu' tabIndex={5} onClick={()=>{window.ReactNativeWebView.postMessage("openNetflix")}}>
                        <img src='icons/vidio.png' alt='Vidio' height={22} />
                    </button>
                    <button className='bmenu' tabIndex={6} onClick={()=>{window.ReactNativeWebView.postMessage("openNetflix")}}>
                        <img src='icons/vision-plus.png' alt='Vision Plus' height={20} />
                    </button>
                    <button className='bmenu' tabIndex={7}>
                        <img src='icons/disney.png' alt='Disney +' height={32} />
                    </button>
                    <button className='bmenu' tabIndex={8} onClick={()=>{ openPage('worldclock')}}>
                        <img src='icons/worldclock.png' alt='World Clock' height={32} />
                    </button>
                    {/*<button className='bmenu' tabIndex={9} onClick={()=>{ openPage('flight')}}>
                        <img src='icons/flight.png' alt='Flight Schedule' height={32} />
                    </button>
                    <button className='bmenu' tabIndex={10} onClick={()=>{ openPage('transport')}}>
                        <img src='icons/transport.png' alt='Transport' height={26} />
                    </button>*/}
                    <button className='bmenu' tabIndex={11} onClick={()=>{ openPage('fnb')}}>
                        <img src='icons/fnb.png' alt='Food and Beverage' height={32} />
                    </button>
                    <button className='bmenu' tabIndex={8} onClick={()=>{ openPage('activities')}}>
                        <img src='icons/discount.png' alt='Activities' height={32} />
                    </button>
                    <button className='bmenu' tabIndex={12} onClick={()=>{ openPage('helpdesk')}}>
                        <img src='icons/helpdesk.png' alt='Helpdesk' height={32} />
                    </button>
                    {/*<button className='bmenu' tabIndex={12} onClick={()=>{ openPage('wifi')}}>
                        <img src='icons/wifi.png' alt='Wifi' height={32} />
                    </button>
                    <button className='bmenu' tabIndex={13} onClick={()=>{ openPage('wakeupcall')}}>
                        <img src='icons/wake-up.png' alt='Wakeup Call' height={32} />
                    </button>
                    <button className='bmenu' tabIndex={13} onClick={()=>{ openPage('settings')}}>
                        <img src='icons/settings.png' alt='Settings' height={27} />
                    </button>*/}
                    
                </Col>
            </Row>
        </div>
    </>
  );
};

export default Main;


