import React, { useState,useEffect,useRef, useCallback } from 'react';
import { Row, Col } from "react-bootstrap";
import { AnimatePresence,motion } from 'framer-motion';
import useEmblaCarousel from 'embla-carousel-react'

import '../lib/bootstrap.min.css'
import './Main.css';
import './SubPage.css';
import './Property.css';

const Property = (props) => {

    const ref = useRef(null);
    const refMenu = useRef(null);
    const refContent = useRef(null);
    const [height,setHeight] = useState(0)
    const [width,setWidth] = useState(0)

    const [cheight,setcHeight] = useState(0)
    const [cwidth,setcWidth] = useState(0)
    const [topMenu,setTopMenu] = useState(0)
    const [topMenu1,setTopMenu1] = useState(0)
    const [emblaRef, emblaApi] = useEmblaCarousel()
    const [prevDisable,setPrevDisable] = useState(false)
    const [nextDisable,setNextDisable] = useState(false)

    
    const scrollPrev = useCallback(() => {
        if (emblaApi) emblaApi.scrollPrev()
          //setPrevDisable(!emblaApi.canScrollPrev)
          //setNextDisable(!emblaApi.canScrollNext)
      }, [emblaApi])
    
      const scrollNext = useCallback(() => {
        if (emblaApi) emblaApi.scrollNext()
          //setPrevDisable(!emblaApi.canScrollPrev)
          //setNextDisable(!emblaApi.canScrollNext)
      }, [emblaApi])
      const scrolTo = useCallback((index) => {
          if (emblaApi) emblaApi.scrollTo(index)
        }, [emblaApi])
  

    useEffect(() => {
        setHeight(ref.current.clientHeight);
        setWidth(ref.current.clientWidth);

        setcHeight(refContent.current.clientHeight);
        setcWidth(refContent.current.clientWidth);
        setTopMenu((ref.current.clientHeight/2)-(refMenu.current.clientHeight/2))
    }, []);

  return (
    <AnimatePresence>
        <style>{`
            .plMenu1{
                top: `+topMenu1+`px 
            }
        `}</style>
        <Row>
            <Col md={12}>
                <motion.div 
                    ref={ref}
                    className='content-subpage'
                    style={{height:(props.height)+'px'}}
                    initial={{ opacity: 0, marginTop:-50 }}
                    animate={{ opacity: 1, marginTop: 0,  }}
                    exit={{opacity:0, marginTop:300}}
                    transition={{ duration: 1.2, ease: [0.87, 0, 0.13, 1] }}
                >
                    <Row>
                        <Col md={3} className='property-left' style={{height:(props.height)+'px'}}>
                            <motion.div style={{margin:'0px', width:'100%'}}
                                    initial={{ opacity: 0, marginTop:-50 }}
                                    animate={{ opacity: 1, marginTop: 0,  }}
                                    exit={{opacity:0, marginTop:300}}
                                    transition={{ duration: 1.2, ease: [0.87, 0, 0.13, 1] }}
                                >
                                <div className='plTitle'>
                                    <h2>Hotel</h2>
                                    <h3>Information</h3>
                                </div>
                                </motion.div>
                                <motion.div style={{margin:'0px', width:'100%'}}
                                    initial={{ opacity: 0, marginleftt:-50 }}
                                    animate={{ opacity: 1, marginLeft: 0,  }}
                                    exit={{opacity:0, marginLeft:300}}
                                    transition={{ duration: 1.2, ease: [0.87, 0, 0.13, 1] }}
                                >
                                <div ref={refMenu} className='plMenu'>
                                    <button>Overview</button>
                                    <button>Hotel Facilities</button>
                                    <button>Accommodation</button>
                                    <button>Policies</button>
                                    <button>Meetings &amp; Events</button>
                                    <button>Arround Us</button>
                                </div>
                            </motion.div>
                        </Col>
                        <Col md={9} style={{padding:0}}>
                            <Row  style={{padding:0}}>
                                <Col md={12} style={{padding:0}}>
                                    <motion.div ref={refContent} style={{height:height}}>
                                        <div className="embla">
                                            <div className="embla__viewport" ref={emblaRef} onFocus={()=>{document.getElementById('next').focus()}}>
                                                <div className="embla__container">
                                                    <div className="embla__slide" style={{height:height}}>
                                                        <img src='bintangKutaHotel/photos/overview.jpg' style={{width:'100%'}} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='embla__nav'>
                                                <button id='prev' className="embla__prev btnFNB" onClick={scrollPrev} disabled={prevDisable}>
                                                    &laquo; Prev
                                                </button>
                                                <button id='next' className="embla__next btnFNB" onClick={scrollNext} disabled={nextDisable}>
                                                    Next &raquo;
                                                </button>
                                            </div>
                                        </div>
                                    </motion.div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </motion.div>
            </Col>
        </Row>
    </AnimatePresence>
  )};

export default Property;


