import React, { useState,useEffect,useRef, useCallback } from 'react';
import { Row, Col } from "react-bootstrap";
import { AnimatePresence,motion } from 'framer-motion';
import useEmblaCarousel from 'embla-carousel-react'

import '../lib/bootstrap.min.css'
import './Main.css';
import './SubPage.css';

const Fnb = (props) => {

    const ref = useRef(null);
    const [height,setHeight] = useState(0)
    const [width,setWidth] = useState(0)
    const [prevDisable,setPrevDisable] = useState(false)
    const [nextDisable,setNextDisable] = useState(false)

    const [emblaRef, emblaApi] = useEmblaCarousel()

    const scrollPrev = useCallback(() => {
      if (emblaApi) emblaApi.scrollPrev()
        //setPrevDisable(!emblaApi.canScrollPrev)
        //setNextDisable(!emblaApi.canScrollNext)
    }, [emblaApi])
  
    const scrollNext = useCallback(() => {
      if (emblaApi) emblaApi.scrollNext()
        //setPrevDisable(!emblaApi.canScrollPrev)
        //setNextDisable(!emblaApi.canScrollNext)
    }, [emblaApi])
    const scrolTo = useCallback((index) => {
        if (emblaApi) emblaApi.scrollTo(index)
      }, [emblaApi])

    useEffect(() => {
        setHeight(ref.current.clientHeight);
        setWidth(ref.current.clientWidth);
    }, []);

  return (
    <AnimatePresence>
        <Row>
            <Col md={12}>
                <motion.div 
                    ref={ref}
                    className='content-subpage'
                    style={{height:(props.height)+'px'}}
                    initial={{ opacity: 0, marginTop:-50 }}
                    animate={{ opacity: 1, marginTop: 0,  }}
                    exit={{opacity:0, marginTop:300}}
                    transition={{ duration: 1.2, ease: [0.87, 0, 0.13, 1] }}
                >
                    <div className="embla">
                        <div className="embla__viewport" ref={emblaRef} onFocus={()=>{document.getElementById('next').focus()}}>
                            <div className="embla__container">
                                <div className="embla__slide" style={{height:height, background:'url(http://vision.ebizpro.id/menu-1.jpg) no-repeat'}}>&nbsp;</div>
                                <div className="embla__slide" style={{height:height, background:'url(http://vision.ebizpro.id/menu-2.jpg) no-repeat'}}>&nbsp;</div>
                                <div className="embla__slide" style={{height:height, background:'url(http://vision.ebizpro.id/menu-1.jpg) no-repeat'}}>&nbsp;</div>
                            </div>
                        </div>
                        <div className='embla__nav' style={{width:width}}>
                            <button id='prev' className="embla__prev btnFNB" onClick={scrollPrev} disabled={prevDisable}>
                                &laquo; Prev
                            </button>
                            <button id='next' className="embla__next btnFNB" onClick={scrollNext} disabled={nextDisable}>
                                Next &raquo;
                            </button>
                            <button id='order' className="btnFNB" onClick={()=>{scrolTo(2)}}>
                                How to Order ?
                            </button>
                        </div>
                    </div>

                </motion.div>
            </Col>
        </Row>
    </AnimatePresence>
  )};

export default Fnb;


