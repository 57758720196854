import React, { useState,useEffect,useRef } from 'react';

import { Row, Col } from "react-bootstrap";
import { AnimatePresence,motion } from 'framer-motion';
import '../lib/bootstrap.min.css'
import './Main.css';
import './SubPage.css';

const Setting = (props) => {
  return (
    <AnimatePresence>
        <Row>
            <Col md={12}>
                <motion.div 
                    className='content-setting'
                    initial={{ opacity: 0, marginLeft:-300 }}
                    animate={{ opacity: 1, marginLeft: 0,  }}
                    exit={{opacity:0, marginLeft: -300}}
                    transition={{ duration: 0.8, ease: [0.7, 0, 0.3, 1] }}
                >
                    <button className="btnHome"  onClick={()=>{window.ReactNativeWebView.postMessage("openSetting")}}>
                        Setting
                    </button>
                    <button className="btnHome" onClick={()=>{window.ReactNativeWebView.postMessage("openConsole")}}>
                        Open Console
                    </button>
                    <button className="btnHome"  onClick={()=>{window.ReactNativeWebView.postMessage("openLauncher")}}>
                        Activate TV
                    </button>
                </motion.div>
            </Col>
        </Row>
    </AnimatePresence>
  )};

export default Setting;


