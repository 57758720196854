import React from 'react';

import { Row, Col } from "react-bootstrap";
import { AnimatePresence,motion } from 'framer-motion';
import '../lib/bootstrap.min.css'
import './Main.css';
import './License.css';

const License = (props) => {    
  return (
    <div  id="license">
        <AnimatePresence>
            <Row>
                <Col md={12}>
                    <motion.div 
                        className='content-sn'
                        initial={{ opacity: 0, marginTop: 0 }}
                        animate={{ opacity: 1, marginTop: 0,  }}
                        exit={{opacity:0, marginTop: -300}}
                        transition={{ duration: 0.8, ease: [0.7, 0, 0.3, 1] }}
                    >
                        <div className='sn'>
                            <h2>Enter License Key</h2>
                            <input type='text' name='sn1' maxLength={4} value={props.valid} /> - 
                            <input type='text' name='sn2' /> - 
                            <input type='text' name='sn3' /> - 
                            <input type='text' name='sn4' />
                        </div>
                    </motion.div>
                </Col>
            </Row>
        </AnimatePresence>
        <button id='skip' tabIndex={0} className='reg' onClick={()=>{props.goToHome()}}>
            Register
        </button>
    </div>
  )};

export default License;


