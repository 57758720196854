import React, { useState,useEffect,useRef } from 'react';

import { Row, Col } from "react-bootstrap";
import { AnimatePresence,motion } from 'framer-motion';
import '../lib/bootstrap.min.css'
import './Main.css';
import './SubPage.css';

const Home = (props) => {
  return (
    <AnimatePresence>
        <Row>
            <Col md={9}>
                <motion.div 
                    className='content-left'
                    initial={{ opacity: 0, marginLeft:-300 }}
                    animate={{ opacity: 1, marginLeft: 0,  }}
                    exit={{opacity:0, marginLeft: -300}}
                    transition={{ duration: 0.8, ease: [0.7, 0, 0.3, 1] }}
                >
                    <h2>Hello Mr. Jhon,</h2>
                    <p>Welcome to Bintang Kuta Hotel, Experience the ultimate convenience at our centrally situated Legian hotel, a stone's throw away from renowned beaches like Double Six and Padma, the airport, delectable dining, lively pubs, premier shopping, and Bali's thrilling Waterbom attractions. </p>
                    <button className="btnHome" style={{float:'right'}} onClick={()=>{props.propertyInfo()}}>
                        Hotel Information &raquo;
                    </button>
                </motion.div>
            </Col>
            <Col md={3}>
                <motion.div className='content-right'
                    initial={{ opacity: 0, marginRight:-300 }}
                    animate={{ opacity: 1, marginRight: 0,  }}
                    exit={{opacity:0, marginRight:-300}}
                    transition={{ duration: 0.8, ease: [0.87, 0, 0.13, 1] }}>
                        <div style={{marginTop:'0px', textAlign: 'center', width:200, float:'right'}}>
                            &nbsp;
                            <p>Scan QR Code <br />to connect WIFI</p>
                            <img src='http://vision.ebizpro.id/qr.jpg' width={100} />
                            <br /><br />
                            <button className='btnHome'>Help ?</button>
                        </div>
                    
                </motion.div>
            </Col>
        </Row>
    </AnimatePresence>
  )};

export default Home;


