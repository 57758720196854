import React from 'react';

import { Row, Col } from "react-bootstrap";
import { AnimatePresence,motion } from 'framer-motion';
import BackgroundVideo from 'next-video/background-video';
import '../lib/bootstrap.min.css'
import './Main.css';
import './Welcome.css';

const Welcome = (props) => {    
  return (
    <div  id="welcome">
        <AnimatePresence>
            <Row>
                <Col md={12}>
                    <motion.div 
                        className='content-welcome'
                        initial={{ opacity: 0, marginTop: 0 }}
                        animate={{ opacity: 1, marginTop: 0,  }}
                        exit={{opacity:0, marginTop: -300}}
                        transition={{ duration: 0.8, ease: [0.7, 0, 0.3, 1] }}
                    >
                        <BackgroundVideo 
                            poster={'images/baleka-pool.jpg'}
                            src={'https://balekaresort.com/wp-content/uploads/Baleka-Website-mute.mp4'} />
                    </motion.div>
                </Col>
            </Row>
        </AnimatePresence>
        <button id='skip' tabIndex={0} className='continue' onClick={()=>{props.goToHome()}}>
            Skip Video
        </button>
    </div>
  )};

export default Welcome;


