import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react';
import { Row, Col } from "react-bootstrap";
import { AnimatePresence,motion } from 'framer-motion';
import '../lib/bootstrap.min.css'
import '../lib/css/shonir_analog_clock.css'
import './Main.css';
import './SubPage.css';

const ClockWidget = forwardRef((props,refParent) => {
    const ref = useRef(null);
    const [_timer,_setTimer] = useState('ok')
    var timer = true
    function int_shonir_analog_clock(id, time_zone, label) {
        var clockEl = document.getElementById(id);
        var dialLines = document.getElementById(id).getElementsByClassName('diallines');
        
        for (var i = 1; i < 60; i++) {
          clockEl.innerHTML += "<div class='diallines'></div>";
          dialLines[i].style.transform = "rotate(" + 6 * i + "deg)";
        }
        
        setInterval(function () {
            if(timer === true ){
                shonir_analog_clock(id, time_zone, label);
            }
        }, 100);
        
    }
        
    function shonir_analog_clock(id, time_zone, label) {
        var clockEl = document.getElementById(id);
    
        if(time_zone){ 
            var sys_date = new Date().toLocaleString("en-US", { timeZone: time_zone });;
            var d = new Date(sys_date);
        }else{
            d = new Date();
        }
    
        const _month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const _day = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
        var h = d.getHours(),
            m = d.getMinutes(),
            s = d.getSeconds(),
            day = d.getDay(),
            date = d.getDate(),
            month = _month[d.getMonth()],
            year = d.getFullYear(),
                    
          hDeg = h * 30 + m * (360/720),
          mDeg = m * 6 + s * (360/3600),
          sDeg = s * 6,
          
          hEl = clockEl.querySelector('.hour-hand'),
          mEl = clockEl.querySelector('.minute-hand'),
          sEl = clockEl.querySelector('.second-hand'),
          dateEl = clockEl.querySelector('.date'),
          timeEl = clockEl.querySelector('.time');
          clockEl.style.backgroundColor = "black";

      if(month < 9) {
        month = "0" + month;
      }
      if(h < 10) {
        h = "0" + h;
      }
      if(m < 10) {
        m = "0" + m;
      }
      

      hEl.style.transform = "rotate("+hDeg+"deg)";
      mEl.style.transform = "rotate("+mDeg+"deg)";
      sEl.style.transform = "rotate("+sDeg+"deg)";
      dateEl.innerHTML = label + "<br />" + date+" / "+month+" / "+year;
      timeEl.innerHTML =  _day[day] + ', '+h+':'+m;
    }
    useImperativeHandle(refParent, () => ({
        exitPage: () => {
            timer=false
        }
    }));
    useEffect(() => {
        int_shonir_analog_clock("Bali", "Asia/Singapore", 'Bali - Indonesia');
        int_shonir_analog_clock("Jkt", "Asia/Jakarta", 'Jakarta - Indonesia');
        int_shonir_analog_clock("Sydney", "Australia/Sydney", 'Sydney - Australia');
        int_shonir_analog_clock("New_York", "America/New_York", 'New York - USA');
        int_shonir_analog_clock("Dubai", "Asia/Dubai", 'Dubai - EUA');
    }, []);

    return (
    <AnimatePresence>
        <Row>
            <Col md={12}>
                <motion.div 
                    ref={ref}
                    className='content-subpage'
                    style={{height:(props.height)+'px'}}
                    initial={{ opacity: 0, marginTop:-50 }}
                    animate={{ opacity: 1, marginTop: 0,  }}
                    exit={{opacity:0, marginTop:300}}
                    transition={{ duration: 1.2, ease: [0.87, 0, 0.13, 1] }}
                >
                    <h2>World Clock</h2>
                    <table style={{width:'100%'}} className='wclock'>
                        <tr>
                            <td>
                                <div id="Bali" class="analog_clock d-flex justify-content-center">
                                    <div>
                                        <div class="date"></div>
                                        <div class="info time"></div>
                                    </div>
                                    <div class="dot"></div>
                                    <div>
                                        <div class="hour-hand"></div>
                                        <div class="minute-hand"></div>
                                        <div class="second-hand"></div>
                                    </div>
                                    <div>
                                        <span class="h_3">3</span>
                                        <span class="h_6">6</span>
                                        <span class="h_9">9</span>
                                        <span class="h_12">12</span>
                                    </div>
                                    <div class="diallines"></div>
                                </div>
                            </td>
                            <td>
                                <div id="Jkt" class="analog_clock d-flex justify-content-center">
                                    <div>
                                        <div class="date"></div>
                                        <div class="time"></div>
                                    </div>
                                    <div class="dot"></div>
                                    <div>
                                        <div class="hour-hand"></div>
                                        <div class="minute-hand"></div>
                                        <div class="second-hand"></div>
                                    </div>
                                    <div>
                                        <span class="h_3">3</span>
                                        <span class="h_6">6</span>
                                        <span class="h_9">9</span>
                                        <span class="h_12">12</span>
                                    </div>
                                    <div class="diallines"></div>
                                </div>
                            </td>

                            <td>
                                <div id="Sydney" class="analog_clock d-flex justify-content-center">
                                    <div>
                                    <div class="date"></div>
                                        <div class="time"></div>
                                    </div>
                                    <div class="dot"></div>
                                    <div>
                                    <div class="hour-hand"></div>
                                    <div class="minute-hand"></div>
                                    <div class="second-hand"></div>
                                    </div>
                                    <div>
                                    <span class="h_3">3</span>
                                    <span class="h_6">6</span>
                                    <span class="h_9">9</span>
                                    <span class="h_12">12</span>
                                    </div>
                                    <div class="diallines"></div>
                                </div>
                            </td>
                            <td>
                                <div id="New_York" class="analog_clock d-flex justify-content-center">
                                    <div>
                                        <div class="date"></div>
                                        <div class="time"></div>
                                    </div>
                                    <div class="dot"></div>
                                    <div>
                                        <div class="hour-hand"></div>
                                        <div class="minute-hand"></div>
                                        <div class="second-hand"></div>
                                    </div>
                                    <div>
                                        <span class="h_3">3</span>
                                        <span class="h_6">6</span>
                                        <span class="h_9">9</span>
                                        <span class="h_12">12</span>
                                    </div>
                                    <div class="diallines"></div>
                                </div>
                            </td>
                            <td>
                                <div id="Dubai" class="analog_clock d-flex justify-content-center">
                                    <div>
                                    <div class="date"></div>
                                        <div class="time"></div>
                                    </div>
                                    <div class="dot"></div>
                                    <div>
                                    <div class="hour-hand"></div>
                                    <div class="minute-hand"></div>
                                    <div class="second-hand"></div>
                                    </div>
                                    <div>
                                    <span class="h_3">3</span>
                                    <span class="h_6">6</span>
                                    <span class="h_9">9</span>
                                    <span class="h_12">12</span>
                                    </div>
                                    <div class="diallines"></div>
                                </div>
                            </td>
                        </tr>
                        
                    </table>
                    
                </motion.div>
            </Col>
        </Row>
    </AnimatePresence>
  )});

export default ClockWidget;


