import React, { useState,useEffect,useRef,useCallback } from 'react';
import { Row, Col } from "react-bootstrap";
import { AnimatePresence,color,motion } from 'framer-motion';
import useEmblaCarousel from 'embla-carousel-react'
import '../lib/bootstrap.min.css'
import './Main.css';
import './Helpdesk.css';

const Helpdesk = (props) => {

    const ref = useRef(null);
    const refContent = useRef(null);
    const [height,setHeight] = useState(0)
    const [width,setWidth] = useState(0)
    const [emblaRef, emblaApi] = useEmblaCarousel({ axis: 'y' })
    const [prevDisable,setPrevDisable] = useState(false)
    const [nextDisable,setNextDisable] = useState(false)

    
    const scrollPrev = useCallback(() => {
        if (emblaApi) emblaApi.scrollPrev()
          //setPrevDisable(!emblaApi.canScrollPrev)
          //setNextDisable(!emblaApi.canScrollNext)
      }, [emblaApi])
    
      const scrollNext = useCallback(() => {
        if (emblaApi) emblaApi.scrollNext()
          //setPrevDisable(!emblaApi.canScrollPrev)
          //setNextDisable(!emblaApi.canScrollNext)
      }, [emblaApi])
      const scrolTo = useCallback((index) => {
          if (emblaApi) emblaApi.scrollTo(index)
        }, [emblaApi])
  


    useEffect(() => {
        setHeight(ref.current.clientHeight);
        setWidth(ref.current.clientWidth);
    }, []);

  return (
    <AnimatePresence>
        <Row>
            <Col md={12}>
                <motion.div 
                    ref={ref}
                    className='content-helpdesk'
                    style={{height:(props.height)+'px', padding:'0px'}}
                    initial={{ opacity: 0, marginTop:-50 }}
                    animate={{ opacity: 1, marginTop: 0,  }}
                    exit={{opacity:0, marginTop:300}}
                    transition={{ duration: 1.2, ease: [0.87, 0, 0.13, 1] }}
                >
                    <Row>
                        <Col md={3} className='ch-left' style={{height:(props.height)+'px'}}>
                            <motion.div style={{margin:'0px', width:'100%'}}>
                                <div className='helpPhoto'>
                                    <img src='images/helpdesk.png' /></div>
                                <div className='helpTitle'>
                                    <h2>Helpdesk</h2>
                                    <h3>Dashboard</h3>
                                </div>
                            </motion.div>
                        </Col>
                        <Col md={9} style={{height:(props.height)+'px', overflow:'hidden', padding:'20px 30px'}}>
                            <motion.div ref={refContent}>
                                    <div className='tabHeader'>
                                        <table style={{width:'100%'}} className='tabHHelpdesk'>
                                            <thead>
                                                <tr>
                                                    <td width={'15%'} align='center'>Date</td>
                                                    <td width={'40%'}>Description</td>
                                                    <td width={'10%'} align='center'>Duration</td>
                                                    <td width={'15%'} align='center'>Status</td>
                                                    <td width={'20%'}>Note</td>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                    <div className="embla_helpdesk" style={{height:height-70}}>
                                        <div className="embla__viewport_helpdesk" ref={emblaRef} style={{height:height-130}} onFocus={()=>{document.getElementById('next').focus()}}>
                                            <div className="embla__container_helpdesk" style={{height:height-135}}>
                                                <div className="embla__slide_helpdesk" style={{height:height-150}}>
                                                    <table style={{width:'100%'}} className='tabCHelpdesk'>
                                                        <tbody>
                                                        <tr>
                                                            <td width={'15%'} align='center'>11/18 09:20</td>
                                                            <td width={'40%'}>Description</td>
                                                            <td width={'10%'} align='center'>00:20</td>
                                                            <td width={'15%'} align='center'>Progress</td>
                                                            <td width={'20%'}>Note</td>
                                                        </tr>
                                                        <tr>
                                                            <td width={'15%'} align='center'>11/18 09:20</td>
                                                            <td width={'40%'}>Description</td>
                                                            <td width={'10%'} align='center'>00:20</td>
                                                            <td width={'15%'} align='center'>Progress</td>
                                                            <td width={'20%'}>Note</td>
                                                        </tr>
                                                        <tr>
                                                            <td width={'15%'} align='center'>11/18 09:20</td>
                                                            <td width={'40%'}>Description</td>
                                                            <td width={'10%'} align='center'>00:20</td>
                                                            <td width={'15%'} align='center'>Progress</td>
                                                            <td width={'20%'}>Note</td>
                                                        </tr>
                                                        <tr>
                                                            <td width={'15%'} align='center'>11/18 09:20</td>
                                                            <td width={'40%'}>Description</td>
                                                            <td width={'10%'} align='center'>00:20</td>
                                                            <td width={'15%'} align='center'>Progress</td>
                                                            <td width={'20%'}>Note</td>
                                                        </tr>
                                                        <tr>
                                                            <td width={'15%'} align='center'>11/18 09:20</td>
                                                            <td width={'40%'}>Description</td>
                                                            <td width={'10%'} align='center'>00:20</td>
                                                            <td width={'15%'} align='center'>Progress</td>
                                                            <td width={'20%'}>Note</td>
                                                        </tr>
                                                        <tr>
                                                            <td width={'15%'} align='center'>11/18 09:20</td>
                                                            <td width={'40%'}>Description</td>
                                                            <td width={'10%'} align='center'>00:20</td>
                                                            <td width={'15%'} align='center'>Progress</td>
                                                            <td width={'20%'}>Note</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="embla__slide_helpdesk" style={{height:height-150}}>
                                                    <table style={{width:'100%'}} className='tabCHelpdesk'>
                                                        <tbody>
                                                        
                                                        <tr>
                                                            <td width={'15%'} align='center'>11/18 09:20</td>
                                                            <td width={'40%'}>Description</td>
                                                            <td width={'10%'} align='center'>00:20</td>
                                                            <td width={'15%'} align='center'>Progress</td>
                                                            <td width={'20%'}>Note</td>
                                                        </tr>
                                                        <tr>
                                                            <td width={'15%'} align='center'>11/18 09:20</td>
                                                            <td width={'40%'}>Description</td>
                                                            <td width={'10%'} align='center'>00:20</td>
                                                            <td width={'15%'} align='center'>Progress</td>
                                                            <td width={'20%'}>Note</td>
                                                        </tr>
                                                        <tr>
                                                            <td width={'15%'} align='center'>11/18 09:20</td>
                                                            <td width={'40%'}>Description</td>
                                                            <td width={'10%'} align='center'>00:20</td>
                                                            <td width={'15%'} align='center'>Progress</td>
                                                            <td width={'20%'}>Note</td>
                                                        </tr>
                                                        <tr>
                                                            <td width={'15%'} align='center'>11/18 09:20</td>
                                                            <td width={'40%'}>Description</td>
                                                            <td width={'10%'} align='center'>00:20</td>
                                                            <td width={'15%'} align='center'>Progress</td>
                                                            <td width={'20%'}>Note</td>
                                                        </tr>
                                                        <tr>
                                                            <td width={'15%'} align='center'>11/18 09:20</td>
                                                            <td width={'40%'}>Description</td>
                                                            <td width={'10%'} align='center'>00:20</td>
                                                            <td width={'15%'} align='center'>Progress</td>
                                                            <td width={'20%'}>Note</td>
                                                        </tr>
                                                        <tr>
                                                            <td width={'15%'} align='center'>11/18 09:20</td>
                                                            <td width={'40%'}>Description</td>
                                                            <td width={'10%'} align='center'>00:20</td>
                                                            <td width={'15%'} align='center'>Progress</td>
                                                            <td width={'20%'}>Note</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="embla__slide_helpdesk" style={{height:height-150}}>
                                                    <table style={{width:'100%'}} className='tabCHelpdesk'>
                                                        <tbody>
                                                        <tr>
                                                            <td width={'15%'} align='center'>11/18 09:20</td>
                                                            <td width={'40%'}>Description</td>
                                                            <td width={'10%'} align='center'>00:20</td>
                                                            <td width={'15%'} align='center'>Progress</td>
                                                            <td width={'20%'}>Note</td>
                                                        </tr>
                                                        <tr>
                                                            <td width={'15%'} align='center'>11/18 09:20</td>
                                                            <td width={'40%'}>Description</td>
                                                            <td width={'10%'} align='center'>00:20</td>
                                                            <td width={'15%'} align='center'>Progress</td>
                                                            <td width={'20%'}>Note</td>
                                                        </tr>
                                                        <tr>
                                                            <td width={'15%'} align='center'>11/18 09:20</td>
                                                            <td width={'40%'}>Description</td>
                                                            <td width={'10%'} align='center'>00:20</td>
                                                            <td width={'15%'} align='center'>Progress</td>
                                                            <td width={'20%'}>Note</td>
                                                        </tr>
                                                        <tr>
                                                            <td width={'15%'} align='center'>11/18 09:20</td>
                                                            <td width={'40%'}>Description</td>
                                                            <td width={'10%'} align='center'>00:20</td>
                                                            <td width={'15%'} align='center'>Progress</td>
                                                            <td width={'20%'}>Note</td>
                                                        </tr>
                                                        <tr>
                                                            <td width={'15%'} align='center'>11/18 09:20</td>
                                                            <td width={'40%'}>Description</td>
                                                            <td width={'10%'} align='center'>00:20</td>
                                                            <td width={'15%'} align='center'>Progress</td>
                                                            <td width={'20%'}>Note</td>
                                                        </tr>
                                                        <tr>
                                                            <td width={'15%'} align='center'>11/18 09:20</td>
                                                            <td width={'40%'}>Description</td>
                                                            <td width={'10%'} align='center'>00:20</td>
                                                            <td width={'15%'} align='center'>Progress</td>
                                                            <td width={'20%'}>Note</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='embla__nav__helpdesk'>
                                            <button id='prev' className="embla__prev btnFNB" onClick={scrollPrev} disabled={prevDisable}>
                                                &laquo; Prev
                                            </button>
                                            <button id='next' className="embla__next btnFNB" onClick={scrollNext} disabled={nextDisable}>
                                                Next &raquo;
                                            </button>
                                        </div>
                                    </div>
                            </motion.div>
                            
                        </Col>
                    </Row>
                </motion.div>
            </Col>
        </Row>
    </AnimatePresence>
  )};

export default Helpdesk;


